import React from 'react';

const PaymentSuccess = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Thank You for Your Payment!</h1>
      <p>Your payment has been successfully completed.</p>
      <p>We will contact you shortly with more details about your project.</p>
    </div>
  );
};

export default PaymentSuccess;
