import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './WelcomeSection.css';

const WelcomeSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Web and App Development Services</title>
        <meta
          name="description"
          content="Royol Technology specializes in professional web and app development, networking, and security systems. Discover our innovative tech solutions."
        />
      </Helmet>
      <section className="welcome-section-background" id="welcome-section">
        <div className="welcome-container">
          <div className="welcome-text">
            <h3 className="hero-text">Enhance Your Business with Professional Web & App Development</h3>
            <p className="hero-p">
              Transform Your Brand's Digital Identity with Expert Web & App Development. Let's craft
              dynamic, responsive, and user-centric digital experiences that captivate and engage.
              Start your journey to the forefront of innovation with us.
            </p>
            <button className="explore-button" onClick={() => navigate('/quote')}>
              REQUEST A QUOTE
            </button>
          </div>
          <div className="welcome-image">
            <img alt="Dynamic Digital Experiences" src="img/hero-2.png" />
          </div>
        </div>
      </section>
    </>
  );
};

export default WelcomeSection;
