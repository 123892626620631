import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to track if the menu is open
  const navigate = useNavigate(); // For programmatic navigation

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu's visibility
    document.body.style.overflow = menuOpen ? 'hidden' : 'auto'; // Prevent or allow scrolling
  };

  const handleNavigate = (path) => {
    setMenuOpen(false); // Close the menu
    document.body.style.overflow = 'auto'; // Enable scrolling
    navigate(path); // Navigate to the desired route
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <header className="desktop-view">
      <div className="logo-and-nav-li">
        <Link to="/" className="header-logo">
          <div className="menu-toggle" onClick={toggleMenu}>
            {menuOpen ? (
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#f0f0f0"
                  d="M19.41 19.41L12 12l7.41-7.41L19.41 3l-7.41 7.41L4.59 3 3 4.59 10.41 12 3 19.41 4.59 21l7.41-7.41 7.41 7.41z"
                />
              </svg>
            ) : (
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#f0f0f0"
                  d="M17 5H1a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2zm0 5H1a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2zm0 5H1a1 1 0 0 1 0-2h16a1 1 0 1 0 0 2z"
                />
              </svg>
            )}
          </div>
          <img alt="Royol Technology Logo" src="img/white logo.png" />
        </Link>

        <nav className={menuOpen ? 'menu-open' : ''}>
          <ul>
            <div className="desktop-nav">
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Prices
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/portfolio" onClick={() => handleNavigate('/portfolio')}>
                  About
                </Link>
              </li>
            </div>

            <div className="menu-open-nav">
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Prices
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigate('/')}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/portfolio" onClick={() => handleNavigate('/portfolio')}>
                  About
                </Link>
              </li>
              <button className="explore-button" onClick={() => handleNavigate('/quote')}>
                REQUEST A QUOTE
              </button>
              <div className="menu-contact">
                <h4>Contact</h4>
                <p>3900 Haverhill Rd N, #223507, West Palm Beach, FL 33422</p>
                <p>Phone: 239-651-9228</p>
                <p>
                  Email:{' '}
                  <a href="mailto:widmisonfrancois@royoltechnology.com">
                    widmisonfrancois@royoltechnology.com
                  </a>
                </p>
              </div>
              <div className="menu-social-media">
                <a
                  href="https://www.linkedin.com/in/widmison-francois-023185164/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.facebook.com/yourprofile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/widmison/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://github.com/Widmison"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
              </div>
            </div>
          </ul>
        </nav>
      </div>

      <div className="cta-social-media">
        <div className="social-media">
          <a
            href="https://www.linkedin.com/in/widmison-francois-023185164/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://www.facebook.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/widmison/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://github.com/Widmison"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github"></i>
          </a>
        </div>

        <div>
          <button className="header-explore-button" onClick={() => handleNavigate('/quote')}>
            GET STARTED
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
