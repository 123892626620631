import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Pricing.css';

const Pricing = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once while scrolling
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Service-Pricing Plans for Web and App Development</title>
        <meta
          name="description"
          content="Explore our pricing plans for professional web and app development. Choose from Starter, Professional, or Enterprise solutions tailored to your needs."
        />
      </Helmet>
      <section className="pricing-section" id="pricing-section">
        <div className="pricing-table">
          {/* Basic Plan */}
          <div className="pricing-card" data-aos="fade-up">
            <h2>Starter Basic Website</h2>
            <p className="price" id="price-basic">
              Starting at <span id="total-price-basic">$499.99</span>
            </p>
            <p>A small website with 1 to 3 pages, including a homepage, about page, and contact page. May include basic features like a contact form.</p>
            <p>Perfect for personal blogs and small businesses</p>
          </div>

          {/* Standard Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="200">
            <h2>Professional Website</h2>
            <p className="price" id="price-pro">
              Starting at <span id="total-price-pro">$999.99</span>
            </p>
            <p>A medium-sized website with 1 to 5 pages, including additional pages such as services or product listings. Includes more design customization.</p>
            <p>Ideal for businesses needing more functionality</p>
            <p>Custom design and development, e-commerce capabilities, advanced SEO</p>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="400">
            <h2>Enterprise Solutions</h2>
            <p className="price" id="price-enterprise">
              Starting at <span id="total-price-enterprise">$9999.99</span>
            </p>
            <p>A larger website with 1 to 7 pages, offering more content and additional features like a blog, portfolio, or e-commerce integration.</p>
            <p>For large-scale business needs, high-end web design and development, advanced e-commerce, custom integrations.</p>
            <p>Custom design and development, e-commerce capabilities, advanced SEO</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
