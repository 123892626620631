import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Testimonials.css';

const Testimonials = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once while scrolling
    });

    // Simulated reviews data
    const reviewsData = [
      {
        id: 1,
        review: "Their app development services were top-notch. Highly recommend!",
        name: "Jane Smith",
        rating: 5,
      },
      {
        id: 2,
        review: "Very professional and quick to respond. Loved working with them.",
        name: "Michael Johnson",
        rating: 5,
      },
      {
        id: 3,
        review: "Our website now stands out thanks to their creativity and expertise.",
        name: "Emily Davis",
        rating: 5,
      },
    ];
    setReviews(reviewsData);
  }, []);

  return (
    <>
      <Helmet>
        <title>Testimonials - Royol Technology</title>
        <meta
          name="description"
          content="Read what our clients say about Royol Technology's exceptional web and app development services."
        />
      </Helmet>
      <section className="testimonials-section">
        <div className="testimonials-container">
          {/* Left Image */}
          <div className="testimonials-image" data-aos="fade-right">
            <img src="img/reviews.png" alt="Happy Clients" />
          </div>

          {/* Right Reviews */}
          <div className="testimonials-content" data-aos="fade-left">
            <div className="reviews">
              {reviews.map((review, index) => (
                <div
                  className="review-card"
                  key={review.id}
                  data-aos="fade-up"
                  data-aos-delay={`${index * 200}`} // Delayed animation for staggered effect
                >
                  <p className="review-text">"{review.review}"</p>
                  <h4 className="review-author">{review.name}</h4>
                  <div className="rating">
                    {"★".repeat(review.rating)}
                    {"☆".repeat(5 - review.rating)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
