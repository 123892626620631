import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // For SEO
import '../components/Projects.css';
import beyooImage from '../assets/beyoo-store-homepage.png';
import royolImage from '../assets/royol-homepage.png';
import gjpImage from '../assets/gjpcover.png';
import caribbenImage from '../assets/caribbe-n.png';

const Projects = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const projects = [
    {
      id: 1,
      title: "Beyoo Store",
      img: beyooImage,
      description: "An e-commerce platform for a variety of products.",
      responsibilities: "Developed front-end using React, integrated API services, and designed UI/UX.",
      technologies: ["React", "Node.js", "CSS"],
      features: ["User Authentication", "Product Search", "Payment Integration"],
    },
    {
      id: 2,
      title: "Royol Technology",
      img: royolImage,
      description: "A professional website for a tech service company.",
      responsibilities: "Designed website layout, implemented responsive design, and optimized for SEO.",
      technologies: ["HTML", "CSS", "JavaScript"],
      features: ["Responsive Design", "SEO Optimization", "Contact Form"],
    },
    {
      id: 3,
      title: "GJP Cover",
      img: gjpImage,
      description: "A cover design service with online portfolio.",
      responsibilities: "Built custom portfolio section and dynamic project display.",
      technologies: ["React", "TailwindCSS", "Firebase"],
      features: ["Dynamic Portfolio", "User Submissions", "Responsive UI"],
    },
    {
      id: 4,
      title: "Caribbean Services",
      img: caribbenImage,
      description: "Service-based website targeting Caribbean communities.",
      responsibilities: "Created service sections and integrated feedback forms.",
      technologies: ["Vue.js", "SASS", "Axios"],
      features: ["Feedback System", "Service Listings", "User Testimonials"],
    },
  ];

  const navigateProjects = (direction) => {
    const newIndex = currentProjectIndex + direction;
    if (newIndex >= 0 && newIndex < projects.length) {
      setCurrentProjectIndex(newIndex);
    }
  };

  return (
    <section id="projects">
      <Helmet>
        <title>My Projects - Widmison Francois</title>
        <meta
          name="description"
          content="Explore innovative projects by Widmison Francois, including e-commerce platforms and professional web development."
        />
      </Helmet>
      <h2>Completed Work</h2>
      <div className="projects">
        {projects
          .slice(
            currentProjectIndex,
            window.innerWidth >= 1024 ? currentProjectIndex + 2 : currentProjectIndex + 1
          )
          .map((project) => (
            <article className="project" key={project.id}>
              <img
                src={project.img}
                alt={`${project.title} screenshot`}
                className="project-image"
                loading="lazy"
              />
              <div className="project-details">
                <h3>{project.title}</h3>
                <p className="description">{project.description}</p>
                <h4>Role and Responsibilities</h4>
                <p>{project.responsibilities}</p>
                <h4>Technologies and Tools</h4>
                <ul>
                  {project.technologies.map((tech) => (
                    <li key={tech}>{tech}</li>
                  ))}
                </ul>
              </div>
            </article>
          ))}
      </div>
      <div className="navigation-buttons">
        <button
          onClick={() => navigateProjects(-1)}
          disabled={currentProjectIndex === 0}
          aria-label="Previous Projects"
        >
          Prev
        </button>
        <button
          onClick={() => navigateProjects(1)}
          disabled={
            currentProjectIndex + (window.innerWidth >= 1024 ? 2 : 1) >= projects.length
          }
          aria-label="Next Projects"
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default Projects;
