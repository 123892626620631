import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import WelcomeSection from './WelcomeSection';
import Pricing from './Pricing';
import Portfolio from './Portfolio';
import Testimonials from './Testimonials';
import Quote from './Quote';
import PaymentSuccess from './PaymentSuccess';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensures the page scrolls to top on navigation */}
      <Header />
      <Routes>
        {/* Home Page: Includes WelcomeSection, Pricing, Testimonials */}
        <Route
          path="/"
          element={
            <>
              <WelcomeSection />
              <Pricing />
              <Testimonials />
            </>
          }
        />
        {/* Separate Portfolio Page */}
        <Route path="/portfolio" element={<Portfolio />} />
        {/* Separate Quote Form Page */}
        <Route path="/quote" element={<Quote />} />
        {/* Payment Success Page */}
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
