import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './Portfolio.css';
import Skills from './Skills';
import Projects from './Projects';

const Portfolio = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Portfolio - Widmison Francois</title>
        <meta
          name="description"
          content="Explore the portfolio of Widmison Francois, showcasing expertise in web and app development projects across various industries."
        />
      </Helmet>

      {/* Welcome Section */}
      <section className="welcome-section-portfolio">
        <div className="welcome-container-portfolio">
          <div className="welcome-text-portfolio">
            <h3 className="hero-text-portfolio">
              WIDMISON FRANCOIS is an accomplished Web and Software Developer
            </h3>
            <p className="hero-p-portfolio">
              Experience in building innovative software applications and websites.
            </p>
            <button
              className="explore-button-portfolio"
              onClick={() => navigate('/quote')}
            >
              REQUEST A QUOTE
            </button>
          </div>
          <div className="welcome-image-portfolio">
            <img alt="Dynamic Digital Experiences" src="img/hero-2.png" />
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section id="skills-section">
        <Skills />
      </section>

      {/* Projects Section */}
      <section id="projects-section">
        <Projects />
      </section>
    </>
  );
};

export default Portfolio;
